import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['city', 'street', 'state', 'unit', 'zip']

  connect() {
    this.autocomplete = new google.maps.places.Autocomplete(this.streetTarget, {
      componentRestrictions: { country: ['us'] },
      fields: ['address_components'],
      types: ['address']
    })

    // https://stackoverflow.com/a/59619437/399940
    google.maps.event.addDomListener(this.streetTarget, 'focus', e =>
      e.target.setAttribute('autocomplete', 'new-password')
    )

    this.autocomplete.addListener(
      'place_changed',
      this.fillInAddress.bind(this)
    )
  }

  // https://developers.google.com/maps/documentation/javascript/examples/places-autocomplete-addressform
  fillInAddress() {
    const place = this.autocomplete.getPlace()
    let address = ''

    for (const component of place.address_components) {
      const componentType = component.types[0]

      switch (componentType) {
        case 'street_number': {
          address = `${component.long_name} ${address}`

          break
        }

        case 'route': {
          address += component.short_name

          break
        }

        case 'postal_code': {
          this.zipTarget.value = component.long_name

          break
        }

        case 'locality': {
          this.cityTarget.value = component.long_name

          break
        }
        case 'administrative_area_level_1': {
          const stateIndex = Array.from(this.stateTarget.options).findIndex(
            option => option.label === component.long_name
          )

          this.stateTarget.selectedIndex = stateIndex

          break
        }
      }
    }

    this.streetTarget.value = address

    this.unitTarget.focus()
  }
}
