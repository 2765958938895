import { Controller } from '@hotwired/stimulus'
import { render } from 'timeago.js'

export default class extends Controller {
  static targets = ['timestamp']

  timestampTargetConnected(element) {
    render(element)
  }
}
