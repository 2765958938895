import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['autocomplete', 'latitude', 'longitude']

  connect() {
    this.setupAutocomplete()
  }

  setCurrentLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          if (this.hasLatitudeTarget) {
            this.latitudeTarget.value = position.coords.latitude
          }
          if (this.hasLongitudeTarget) {
            this.longitudeTarget.value = position.coords.longitude
          }
        },
        () => console.log('error!')
      )
    }
  }

  setupAutocomplete() {
    const autocomplete = new google.maps.places.Autocomplete(
      this.autocompleteTarget
    )
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      const place = autocomplete.getPlace()
      this.latitudeTarget.value = place.geometry.location.lat()
      this.longitudeTarget.value = place.geometry.location.lng()
    })
  }
}
