import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['hiddenInput']

  connect() {
    this.hiddenInputTarget.value =
      Intl.DateTimeFormat().resolvedOptions().timeZone || 'America/Los_Angeles'
  }
}
