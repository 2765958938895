import { Controller } from '@hotwired/stimulus'
import { Tooltip } from 'bootstrap'

export default class extends Controller {
  static targets = ['link']
  static values = { text: String }

  connect() {
    new Tooltip(this.linkTarget, { trigger: 'hover' })
  }

  copy(event) {
    event.preventDefault()

    navigator.clipboard.writeText(this.textValue)

    const tooltip = Tooltip.getInstance(this.linkTarget)

    tooltip.setContent({ '.tooltip-inner': 'Copied!' })
  }
}
