import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['forkliftExperience']

  toggleForkliftExperience(event) {
    if (event.target.value === 'true') {
      this.forkliftExperienceTarget.disabled = false
    } else {
      this.forkliftExperienceTarget.disabled = true
    }
  }
}
