import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['map']
  static values = {
    deliveryRequests: Array,
    driver: Object
  }

  initialize() {
    const map = new google.maps.Map(this.mapTarget, {
      center: {
        lat: this.driverValue.driver_detail.latitude,
        lng: this.driverValue.driver_detail.longitude
      },
      mapId: '78e7f4e16c0ff8c4',
      zoom: 17
    })

    this.setDriverMarker(map)
    this.setPickupLocationMarkers(map)
  }

  async setDriverMarker(map) {
    const { AdvancedMarkerElement } = await google.maps.importLibrary('marker')

    const driverMarker = document.createElement('div')
    driverMarker.classList.add('driver-marker', 'map-marker')
    driverMarker.textContent = [
      this.driverValue.first_name,
      this.driverValue.last_name
    ].join(' ')

    return new AdvancedMarkerElement({
      content: driverMarker,
      map,
      position: {
        lat: this.driverValue.driver_detail.latitude,
        lng: this.driverValue.driver_detail.longitude
      }
    })
  }

  async setPickupLocationMarkers(map) {
    const { AdvancedMarkerElement } = await google.maps.importLibrary('marker')
    this.deliveryRequestsValue.map(deliveryRequest => {
      const pickupLocationMarker = document.createElement('div')
      pickupLocationMarker.classList.add('map-marker', 'pickup-location-marker')
      pickupLocationMarker.textContent = deliveryRequest.pickup_location.name

      return new AdvancedMarkerElement({
        content: pickupLocationMarker,
        map,
        position: {
          lat: deliveryRequest.pickup_location.address.latitude,
          lng: deliveryRequest.pickup_location.address.longitude
        }
      })
    })
  }
}
