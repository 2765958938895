import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['form', 'key', 'stripeCardToken']
  static values = { publicKey: String }

  async connect() {
    // don't worry, it's the public key
    const stripe = Stripe(this.publicKeyValue)
    const elements = stripe.elements()
    // Custom styling can be passed to options when creating an Element.
    const style = {
      base: {
        // Add your base input styles here. For example:
        fontSize: '16px'
      }
    }

    // Create an instance of the card Element.
    const card = elements.create('card', { style })

    // Add an instance of the card Element into the `card-element` <div>.
    card.mount('#card-element')
    const that = this

    this.formTarget.addEventListener('submit', function (event) {
      event.preventDefault()

      stripe
        .createPaymentMethod({
          type: 'card',
          card
        })
        .then(function (result) {
          if (result.error) {
            // Inform the customer that there was an error.
            const errorElement = document.getElementById('card-errors')
            errorElement.textContent = result.error.message
          } else {
            that.stripeTokenHandler(result)
          }
        })
    })
  }

  async stripeTokenHandler(result) {
    // Insert the token ID into the form so it gets submitted to the server
    this.stripeCardTokenTarget.value = result.paymentMethod.id

    // Submit the form
    this.formTarget.submit()
  }
}
