import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['addItem', 'counter', 'template']

  addLocation(event) {
    event.preventDefault()

    const content = this.templateTarget.innerHTML.replace(
      /0123456789/g,
      new Date().valueOf()
    )

    this.addItemTarget.insertAdjacentHTML('beforebegin', content)

    this.updateCounters()
  }

  removeLocation(event) {
    event.preventDefault()

    event.target.closest('.delivery-location').remove()

    this.updateCounters()
  }

  updateCounters() {
    this.counterTargets.forEach((counter, index) => {
      counter.textContent = index + 2
    })
  }
}
