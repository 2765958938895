import { Controller } from '@hotwired/stimulus'
import dayjs from 'dayjs'
import flatpickr from 'flatpickr'

export default class extends Controller {
  static targets = ['input']
  static values = { days: Array }

  connect() {
    flatpickr(this.inputTarget, {
      allowInput: true,
      altFormat: 'n/j/y l',
      altInput: true,
      altInputClass: 'flatpickr form-select select',
      dateFormat: 'Z',
      disable: [
        date => this.daysValue.length && !this.daysValue.includes(date.getDay())
      ],
      formatDate: (date, format) => {
        if (format === 'Z' && date.getHours() === 0) {
          date = dayjs(date).add(6.5, 'hour')
        }

        return flatpickr.formatDate(date, format)
      },
      minDate: Date.now()
    })
  }

  disconnect() {
    this.inputTarget.flatpickr('destroy')
  }
}
