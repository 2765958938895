import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['locationExisting', 'locationNew']

  toggle() {
    this.locationExistingTarget.disabled = !this.locationExistingTarget.disabled
    this.locationNewTarget.disabled = !this.locationNewTarget.disabled
  }
}
