import { Controller } from '@hotwired/stimulus'
import consumer from '../channels/consumer'

export default class extends Controller {
  static targets = ['frame']
  static values = { id: Number }

  connect() {
    consumer.subscriptions.create(
      { channel: 'DeliveryNotificationsChannel' },
      {
        received: ({ id }) => {
          if (this.idValue && id !== this.idValue) return

          this.frameTarget.src = window.location.href
        }
      }
    )
  }
}
