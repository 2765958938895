import { Controller } from '@hotwired/stimulus'
import { startCase } from 'lodash'
import consumer from '../../channels/consumer'

export default class extends Controller {
  static targets = ['deliveryRequestFrame', 'deliveryRequestTable', 'map']

  connect() {
    const deliveryRequests = JSON.parse(
      this.deliveryRequestTableTarget.dataset.deliveryRequests
    )

    this.infoWindow = new google.maps.InfoWindow({ maxWidth: 300 })
    this.lines = []
    this.markers = []
    this.map = new google.maps.Map(this.mapTarget, {
      center: {
        lat: deliveryRequests[0].pickup_location.address.latitude,
        lng: deliveryRequests[0].pickup_location.address.longitude
      },
      zoom: 12
    })

    this.setup()
    this.listenForChanges()
  }

  setup() {
    const { deliveryRequests, drivers, locations } =
      this.deliveryRequestTableTarget.dataset

    this.deliveryRequests = JSON.parse(deliveryRequests)
    this.drivers = JSON.parse(drivers)
    this.locations = JSON.parse(locations)

    this.deliveryRequests.map(deliveryRequest => this.setLines(deliveryRequest))

    this.setDriverMarkers()
    this.setLocationMarkers()
  }

  listenForChanges() {
    consumer.subscriptions.create(
      { channel: 'DeliveryNotificationsChannel' },
      {
        received: () => {
          this.deliveryRequestFrameTarget.src = '/admin/map'

          this.deliveryRequestFrameTarget.loaded.then(() => {
            let { drivers, locations } = this.deliveryRequestTableTarget.dataset

            drivers = JSON.parse(drivers)
            locations = JSON.parse(locations)

            this.lines.map(line => line.setMap(null))
            this.markers.map(marker => {
              if (
                drivers.find(
                  driver =>
                    driver.latitude === marker.position.lat() &&
                    driver.longitude === marker.position.lng()
                ) ||
                locations.find(
                  location =>
                    location.address.latitude === marker.position.lat() &&
                    location.address.longitude === marker.position.lng()
                )
              ) {
                return
              }

              marker.setMap(null)
            })

            this.setup()
          })
        }
      }
    )
  }

  setDriverMarkers() {
    this.drivers.map(driver => {
      const { first_name, id, last_name, latitude, longitude } = driver

      if (
        this.markers.find(
          marker =>
            marker.position.lat() === latitude &&
            marker.position.lng() === longitude
        )
      ) {
        return
      }

      const marker = new google.maps.Marker({
        label: [first_name, last_name].join(' '),
        map: this.map,
        position: {
          lat: latitude,
          lng: longitude
        }
      })

      marker.addListener('click', () => {
        const deliveryRequests = this.deliveryRequests.filter(
          deliveryRequest => {
            return deliveryRequest.driver_assignments.find(driverAssignment => {
              return driverAssignment.user.id === id
            })
          }
        )

        this.infoWindow.setContent(
          [
            `<h6 style="margin-bottom:0">${[first_name, last_name].join(
              ' '
            )}</h6>`,
            '<strong>Driver</strong><br><br>',
            deliveryRequests
              .map(deliveryRequest =>
                [
                  `<strong>${deliveryRequest.order_number}</strong> ${startCase(
                    deliveryRequest.status
                  )}`,
                  `<small>from ${deliveryRequest.pickup_location.name}</small>`
                ].join('<br>')
              )
              .join('<br><br/>')
          ].join('')
        )

        this.infoWindow.open({
          anchor: marker,
          map: this.map,
          shouldFocus: false
        })
      })
      this.markers.push(marker)
    })
  }

  setLocationMarkers() {
    this.locations.map(location => {
      const {
        name,
        address: {
          city,
          street,
          state: { abbr },
          zip
        }
      } = location

      if (
        this.markers.find(
          marker =>
            marker.position.lat() === location.address.latitude &&
            marker.position.lng() === location.address.longitude
        )
      ) {
        return
      }

      const marker = new google.maps.Marker({
        map: this.map,
        position: {
          lat: location.address.latitude,
          lng: location.address.longitude
        },
        label: name
      })

      const deliveries = this.deliveryRequests.filter(deliveryRequest => {
        const {
          delivery_location: {
            address: { latitude, longitude }
          }
        } = deliveryRequest

        return (
          latitude === location.address.latitude &&
          longitude === location.address.longitude
        )
      })
      const pickups = this.deliveryRequests.filter(deliveryRequest => {
        const {
          pickup_location: {
            address: { latitude, longitude }
          }
        } = deliveryRequest

        return (
          latitude === location.address.latitude &&
          longitude === location.address.longitude
        )
      })

      marker.addListener('click', () => {
        this.infoWindow.setContent(
          [
            `<h6 style="margin-bottom:0">${name}</h6>`,
            '<p>',
            street,
            '<br>',
            city,
            ', ',
            abbr,
            ' ',
            zip,
            '</p>',
            [
              deliveries.length > 0
                ? '<h6 style="font-size:14px">Delivery</h6>'
                : '',
              deliveries
                .map(deliveryRequest =>
                  [
                    `<strong>${
                      deliveryRequest.order_number
                    }</strong> ${startCase(deliveryRequest.status)}`,
                    `<small>from ${deliveryRequest.pickup_location.name}</small>`
                  ].join('<br>')
                )
                .join('<br><br/>'),
              pickups.length > 0 && deliveries.length > 0 ? '<br><br>' : '',
              pickups.length > 0
                ? '<h6 style="font-size:14px">Pickup</h6>'
                : '',
              pickups
                .map(deliveryRequest =>
                  [
                    `<strong>${
                      deliveryRequest.order_number
                    }</strong> ${startCase(deliveryRequest.status)}`,
                    `<small>to ${deliveryRequest.delivery_location.name}</small>`
                  ].join('<br>')
                )
                .join('<br><br/>')
            ].join('')
          ].join('')
        )

        this.infoWindow.open({
          anchor: marker,
          map: this.map,
          shouldFocus: false
        })
      })

      this.markers.push(marker)
    })
  }

  setLines(deliveryRequest) {
    const driverAssignment = deliveryRequest.driver_assignments.find(
      driver_assignment =>
        ['available', 'in_progress'].includes(driver_assignment.status)
    )

    const {
      delivery_location: {
        address: { latitude: deliveryLatitude, longitude: deliveryLongitude }
      },
      pickup_location: {
        address: { latitude: pickupLatitude, longitude: pickupLongitude }
      },
      status
    } = deliveryRequest

    if (['assigning', 'awaiting_pickup'].includes(status) && driverAssignment) {
      this.createLine(
        [
          {
            lat: driverAssignment.user.latitude,
            lng: driverAssignment.user.longitude
          },
          {
            lat: pickupLatitude,
            lng: pickupLongitude
          }
        ],
        '#C100A3'
      )

      this.createLine(
        [
          {
            lat: pickupLatitude,
            lng: pickupLongitude
          },
          {
            lat: deliveryLatitude,
            lng: deliveryLongitude
          }
        ],
        '#C100A3'
      )
    } else if (['picked_up', 'en_route'].includes(status)) {
      this.createLine(
        [
          {
            lat: pickupLatitude,
            lng: pickupLongitude
          },
          {
            lat: driverAssignment.user.latitude,
            lng: driverAssignment.user.longitude
          }
        ],
        '#00CC2D'
      )

      this.createLine(
        [
          {
            lat: driverAssignment.user.latitude,
            lng: driverAssignment.user.longitude
          },
          {
            lat: deliveryLatitude,
            lng: deliveryLongitude
          }
        ],
        '#00CC2D'
      )
    } else {
      this.createLine(
        [
          {
            lat: pickupLatitude,
            lng: pickupLongitude
          },
          {
            lat: deliveryLatitude,
            lng: deliveryLongitude
          }
        ],
        '#0000ff'
      )
    }
  }

  centerOnMarker(event) {
    event.preventDefault()

    const { latitude, longitude } = event.target.dataset

    this.map.panTo({
      lat: parseFloat(latitude),
      lng: parseFloat(longitude)
    })
  }

  createLine(path, strokeColor) {
    const line = new google.maps.Polyline({
      icons: [
        {
          icon: {
            path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW
          },
          offset: '100%'
        }
      ],
      path,
      strokeColor,
      strokeOpacity: 0.75,
      strokeWeight: 2
    })

    line.setMap(this.map)

    this.lines.push(line)
  }
}
