import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['checkbox']

  connect() {
    if (
      this.hasCheckboxTarget &&
      !this.checkboxTargets.some(checkbox => checkbox.checked)
    ) {
      this.checkboxTargets[0].setCustomValidity(
        'Please select at least one region'
      )
    }
  }

  change() {
    if (this.checkboxTargets.some(checkbox => checkbox.checked)) {
      this.checkboxTargets[0].setCustomValidity('')
    } else {
      this.checkboxTargets[0].setCustomValidity(
        'Please select at least one region'
      )
    }
  }
}
