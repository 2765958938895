import { Controller } from '@hotwired/stimulus'
import Inputmask from 'inputmask'

export default class extends Controller {
  static targets = ['phone']

  connect() {
    const inputMask = new Inputmask({
      autoUnmask: true,
      // TODO: a mask of '(999) 999-9999' + chrome autofill causes the numbers to jumble
      // for some reason.  Maybe the mask and the autofill are having a slap fight
      mask: '999-999-9999',
      onBeforeMask: initialValue => {
        if (Array.from(initialValue)[0] === '1') {
          return initialValue.substring(1)
        } else {
          return initialValue
        }
      },
      removeMaskOnSubmit: true
    })

    this.phoneTargets.forEach(input => {
      inputMask.mask(input)
    })
  }
}
