import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['make', 'model', 'year']

  async initialize() {
    if (this.makeTarget.value && this.yearTarget.value) {
      this.setupModelSelect()
    }
  }

  async setupModelSelect() {
    if (this.makeTarget.value && this.yearTarget.value) {
      const makeId =
        this.makeTarget.options[this.makeTarget.selectedIndex].getAttribute(
          'data-make-id'
        )
      const response = await fetch(
        `https://vpic.nhtsa.dot.gov/api/vehicles/GetModelsForMakeIdYear/makeId/${makeId}/modelyear/${this.yearTarget.value}?format=json`
      )
      const parsedResponse = await response.json()
      let models = parsedResponse.Results.map(model => model.Model_Name).sort(
        Intl.Collator().compare
      )
      models = [...new Set(models)]
      const selectBox = this.modelTarget
      selectBox.innerHTML = ''
      const includeBlank = new Option('Select one', '')
      selectBox.append(includeBlank)

      models.forEach(model => {
        const opt = document.createElement('option')
        opt.value = model
        opt.innerHTML = model
        if (this.modelTarget.getAttribute('data-value') === model) {
          opt.selected = true
        }
        selectBox.appendChild(opt)
      })
      this.modelTarget.disabled = false
    }
  }
}
