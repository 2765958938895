import { Controller } from '@hotwired/stimulus'
import flatpickr from 'flatpickr'

export default class extends Controller {
  static targets = ['input']
  static values = {
    defaultHour: { default: 0, type: Number },
    defaultMinute: { default: 0, type: Number }
  }

  connect() {
    flatpickr(this.inputTarget, {
      altFormat: 'D M j h:i K',
      altInput: true,
      defaultHour: this.defaultHourValue,
      defaultMinute: this.defaultMinuteValue,
      enableSeconds: true,
      enableTime: true
    })
  }

  disconnect() {
    this.inputTarget.flatpickr('destroy')
  }
}
