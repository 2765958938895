import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['fieldset']

  radioToggle(event) {
    if (['in_progress', 'waitlist'].includes(event.target.value)) {
      this.fieldsetTarget.disabled = false
    } else {
      this.fieldsetTarget.disabled = true
    }
  }
}
