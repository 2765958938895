import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  select(event) {
    const { currentTarget } = event

    currentTarget.parentElement
      .querySelectorAll('tr')
      .forEach(row => (row.className = null))
    currentTarget.className = 'table-secondary'
    currentTarget.querySelector('input[type=radio]').checked = true
  }
}
