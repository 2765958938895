import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['map']
  static values = {
    deliveryLocationAddress: String,
    drivers: Array,
    pickupLocationAddress: String
  }

  initialize() {
    const map = new google.maps.Map(this.mapTarget, {
      mapId: 'b861cb02f21004a9'
    })

    this.calculateAndDisplayRoute(map)
    this.setDriverMarkers(map)
  }

  calculateAndDisplayRoute(map) {
    const directionsRenderer = new google.maps.DirectionsRenderer()
    const directionsService = new google.maps.DirectionsService()

    directionsRenderer.setMap(map)
    directionsService.route(
      {
        destination: this.deliveryLocationAddressValue,
        origin: this.pickupLocationAddressValue,
        travelMode: google.maps.TravelMode.DRIVING
      },
      (response, status) => {
        if (status === 'OK') {
          directionsRenderer.setDirections(response)
        } else {
          window.alert('Directions request failed due to ' + status)
        }
      }
    )
  }

  async setDriverMarkers(map) {
    const { AdvancedMarkerElement } = await google.maps.importLibrary('marker')

    this.driversValue.map(driver => {
      const driverMarker = document.createElement('div')
      driverMarker.classList.add('driver-marker', 'map-marker')
      driverMarker.textContent = [driver.first_name, driver.last_name].join(' ')

      return new AdvancedMarkerElement({
        content: driverMarker,
        map,
        position: {
          lat: driver.driver_detail.latitude,
          lng: driver.driver_detail.longitude
        }
      })
    })
  }
}
