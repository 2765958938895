import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['form']

  filter() {
    const frame = document.getElementById('driver-assignments-frame')
    const src = new URL(frame.src)
    const newSrc = [
      src.origin,
      src.pathname,
      '?',
      new URLSearchParams(new FormData(this.formTarget)).toString()
    ].join('')

    frame.src = newSrc
  }
}
