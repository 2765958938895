import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['checkbox', 'input']
  static values = {
    label: String,
    submit: Boolean
  }

  connect() {
    const checkedCount = this.checkboxTargets.filter(
      checkboxTarget => checkboxTarget.checked
    ).length

    if (checkedCount === 0) {
      this.inputTarget.value = `All ${this.labelValue}`
    } else if (checkedCount === 1) {
      this.inputTarget.value = this.checkboxTargets
        .find(checkboxTarget => checkboxTarget.checked)
        .closest('label')
        .innerText.trim()
    } else {
      this.inputTarget.value = `${checkedCount} ${this.labelValue}`
    }
  }

  unselectAll(event) {
    event.preventDefault()

    this.checkboxTargets.forEach(checkboxTarget => {
      checkboxTarget.checked = false
    })

    this.inputTarget.value = `All ${this.labelValue}`

    if (this.submitValue) {
      event.target.closest('form').requestSubmit()
    }
  }

  change(event) {
    const checkedCount = this.checkboxTargets.filter(
      checkboxTarget => checkboxTarget.checked
    ).length
    if (checkedCount === 0) {
      this.inputTarget.value = `All ${this.labelValue}`
    } else if (checkedCount === 1) {
      this.inputTarget.value = this.checkboxTargets
        .find(checkboxTarget => checkboxTarget.checked)
        .closest('label')
        .innerText.trim()
    } else {
      this.inputTarget.value = `${checkedCount} ${this.labelValue}`
    }

    if (this.submitValue) {
      event.target.form.requestSubmit()
    }
  }
}
