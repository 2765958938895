import { Controller } from '@hotwired/stimulus'
import { Modal } from 'bootstrap'
import autosize from 'autosize'

export default class extends Controller {
  static targets = [
    'modalShared',
    'modalSharedForm',
    'modalSharedFrame',
    'textarea'
  ]

  connect() {
    this.modalSharedTarget.addEventListener('shown.bs.modal', () =>
      autosize.update(this.textareaTargets)
    )

    this.modalSharedTarget.addEventListener(
      'hidden.bs.modal',
      () => (this.modalSharedFrameTarget.innerHTML = '')
    )
  }

  modalSharedFormTargetConnected() {
    Modal.getOrCreateInstance(this.modalSharedTarget).show()
  }
}
