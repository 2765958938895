import './controllers'
import '@fortawesome/fontawesome-free/js/fontawesome'
import '@fortawesome/fontawesome-free/js/solid'
import '@hotwired/turbo-rails'
import 'bootstrap'
import 'chartkick/chart.js'
import 'inputmask'
import * as ActiveStorage from '@rails/activestorage'

ActiveStorage.start()

window.FontAwesome.config.mutateApproach = 'sync'
