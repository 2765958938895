import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['fieldset', 'navLink']

  connect() {
    this.navLinkTargets.forEach(navLink => {
      navLink.addEventListener('shown.bs.tab', () =>
        this.fieldsetTargets.forEach(
          fieldset => (fieldset.disabled = !fieldset.disabled)
        )
      )
    })
  }
}
