import { Controller } from '@hotwired/stimulus'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

export default class extends Controller {
  static targets = ['timestamp']
  static values = {
    format: { default: 'M/D/YY h:mm a', type: String }
  }

  connect() {
    // safari doesn't parse Rails datetime correctly
    dayjs.extend(customParseFormat)

    this.timestampTarget.textContent = dayjs(
      this.timestampTarget.attributes.datetime.value,
      'YYYY-MM-DD HH:mm:ss ZZ'
    ).format(this.formatValue)
  }
}
