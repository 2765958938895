import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'

export default class extends Controller {
  static targets = ['input', 'select', 'submit']

  async change() {
    if (this.selectTarget.value) {
      let vehicleType = await get(
        `/customer/accounts_vehicle_types/${this.selectTarget.value}`,
        { responseKind: 'json' }
      )

      let serviceId = await vehicleType.text

      const date = new Date(this.inputTarget.value).toISOString().split('T')[0]

      if (serviceId) {
        await get(
          `/customer/delivery_time_slots?date=${date}&service_id=${serviceId}`,
          {
            responseKind: 'turbo-stream'
          }
        )
      }
    }
  }
}
