import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['frame']

  change(event) {
    const [namespace, collection, id] = window.location.pathname
      .split('/')
      .filter(Boolean)

    this.frameTarget.src =
      '/' +
      [namespace, 'accounts', event.target.value, collection, id, 'edit'].join(
        '/'
      )
  }
}
