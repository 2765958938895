import { Controller } from '@hotwired/stimulus'
import Inputmask from 'inputmask'

export default class extends Controller {
  static targets = ['input']

  initialize() {
    const inputMask = new Inputmask('currency', {
      numericInput: true,
      onBeforeMask: function (initialValue, opts) {
        const numberValue = initialValue.toString().split('')
        numberValue.splice(numberValue.length - 2, 0, opts.radixPoint)

        return numberValue.join('')
      },
      onUnMask: (_maskedValue, unmaskedValue) => Number(unmaskedValue),
      prefix: '$',
      removeMaskOnSubmit: true,
      rightAlign: false
    })

    inputMask.mask(this.inputTargets)
  }
}
