import { Controller } from '@hotwired/stimulus'
import { Toast } from 'bootstrap'

export default class extends Controller {
  static targets = ['toastFlash']

  toastFlashTargetConnected() {
    Toast.getOrCreateInstance(this.toastFlashTarget).show()
  }
}
