import { Controller } from '@hotwired/stimulus'
import Inputmask from 'inputmask'

export default class extends Controller {
  static targets = ['input']

  inputTargetConnected(input) {
    const inputMask = new Inputmask('999-99-9999', {
      autoUnmask: true,
      removeMaskOnSubmit: true
    })

    inputMask.mask(input)
  }
}
